import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import createStoreInstance from "./store";
import "../public/assets/scss/style.scss";
import axiosPlugin from './plugins/axiosPlugin'; 
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

//Vuexストアの初期化を行うPromiseを作成する
const storePromise = new Promise((resolve) => {
    resolve(createStoreInstance(axiosPlugin));
});

// アプリケーションを作成
const app = createApp(App);

// gtag関数をグローバルスコープで定義
window.dataLayer = window.dataLayer || [];
window.gtag = function() {
  dataLayer.push(arguments);
};

const env = process.env.VUE_APP_ENV;
if (env === 'local' || env === 'stg') {
  // local または stg 環境の場合
  const script1 = document.createElement('script');
  script1.src = "https://www.googletagmanager.com/gtag/js?id=G-3HT6NRST4N";
  script1.async = true;
  document.head.appendChild(script1);

  script1.onload = function() {
    gtag('js', new Date());
    gtag('config', 'G-3HT6NRST4N', {'debug_mode': true});
    console.log('stg');
  };
} else if (env === 'prod') {
  // prod 環境の場合
  const script2 = document.createElement('script');
  script2.src = "https://www.googletagmanager.com/gtag/js?id=G-VECV2XNBF9";
  script2.async = true;
  document.head.appendChild(script2);

  script2.onload = function() {
    gtag('js', new Date());
    gtag('config', 'G-VECV2XNBF9', {'debug_mode': true});
    console.log('prod');
  };
}

// Vuexストアの初期化が完了したらアプリケーションをマウントする
storePromise.then(store => {
  store.dispatch('checkAuth').then(()=>{
    app.use(store).use(router).use(Toast).provide('$axios', axiosPlugin).mount("#app");
  });
});

export default storePromise;