<template>
    <main id="contact" class="wrapper wrapperBd">

        <div class="liPankuzu">
            <ul>
                <li><router-link to="/">ホーム</router-link></li>
                <li>お問い合わせ</li>
            </ul>
        </div>
        <!-- li-pankuzu -->

        <section class="secMainview01">
            <div class="secMainview01Inner container containerLarge">
                <h1 class="secMainview01Title">
                    <img src="/assets/img/common/txtOtoiawase.svg" alt="お問い合わせ" class="secMainview01Jp" />
                </h1>
                <div class="effectBlock effectBlock04"></div>
            </div>
        </section>
        <!-- secMainview01 -->


        <section class="contactThanks container">
            <h2 class="contactThanksTtl">お問い合わせの送信が<br class="pcNone">完了しました。</h2>
            <p>頂いた内容を確認次第ご連絡致しますので、今しばらくお待ちください。<br>
                念のため、送信頂いた内容をご入力いただいたメールアドレスにも控えメールを自動送信させていただきました。<br>
                万一、2営業日以内に折り返しの連絡がない場合は、行き違いが生じている可能性がございます。その場合は、誠に恐れ入りますがお電話(086-286-9670)にてお問い合わせください。
            </p>
            <router-link to="/" class="contactThanksBtn">トップページに戻る</router-link>
        </section>

    </main>
    <!-- #pageID -->
</template>

<script setup>
    import { ref, onMounted, inject, computed } from 'vue';
    import Splide from '@splidejs/splide';
    import { onBeforeRouteLeave,useRoute, useRouter } from 'vue-router';
    const $axios = inject('$axios');
    import { useToast } from "vue-toastification";
    const toast = useToast();
    import VueElementLoading from "vue-element-loading";

    const route = useRoute();
    const company = ref([]);
    const currentUser = computed(() => store.state.currentUser);

    import { useStore } from 'vuex';
    //store/index.jsのグローバルデータ
    const store = useStore();
    const router = useRouter();

    const showVideoModalFlag = ref(false);
    const videoLoadingFlag = ref(false);
    const pickupCompanies = ref([]);
    const dataLoading = ref(true);

    console.log(currentUser.value);

    // mounted
    onMounted(() => {

    });
</script>
