<template>
    <main id="contact" class="wrapper wrapperBd">

        <div class="liPankuzu">
        <ul>
            <li><router-link to="/">ホーム</router-link></li>
            <li>お問い合わせ</li>
        </ul>
        </div>
        <!-- li-pankuzu -->

        <section class="secMainview01">
        <div class="secMainview01Inner container containerLarge">
            <h1 class="secMainview01Title">
                <img src="/assets/img/common/txtOtoiawase.svg" alt="お問い合わせ" class="secMainview01Jp" />
            </h1>
            <div class="effectBlock effectBlock04"></div>
        </div>
        </section>
        <!-- secMainview01 -->


        <div class="contactIntro container">
            <p>入力フォームに必要事項をご記入のうえ<br>『送信する』ボタンをクリックしてください。</p>
            <div class="effectBlock effectBlock05"></div>
        </div>

        <div class="contactForm container">
            <form @submit.prevent="sendForm">

                <dl v-if="officeFlag == false">
                    <dt><span class="must">必須</span><span>希望内容</span></dt>
                    <dd>
                        <ul>
                        <li>
                            <label class="radio" for="otoiawase"><input id="otoiawase" class="contactKibou" type="radio" name="希望内容" value="お問い合わせ" required v-model="inquiryType"><span>お問い合わせ</span></label>
                        </li>
                        <li>
                            <label class="radio" for="webshodan"><input id="webshodan" class="contactKibou" type="radio" name="希望内容" value="WEB商談申込" required v-model="inquiryType"><span>WEB商談申込</span></label>
                        </li>
                        </ul>
                    </dd>
                </dl>

                <div id="contents01" class="contactContents01" v-if="inquiryType == 'お問い合わせ'">
                <table>
                    <tbody>
                    <tr>
                        <th><span class="must">必須</span><span>お問合せ内容</span></th>
                        <td>
                        <!--<p><span  v-if="officeFlag == true">事務局へのご質問を受け付けております。</span><br>下欄にご入力いただき送信ボタンを押してください</p>-->
                        <textarea id="otoiawasenaiyou" rows="8" name="お問合せ内容" placeholder="お問い合わせ内容を入力" required="required" v-model="formData.content"></textarea>
                        <p v-if="officeFlag == true">ご質問以外にも、<br>
                        ・希望に合う受注先等を探してほしい<br>
                        ・出展企業のパンフレットがほしい<br>
                        ・学校向けに出展企業の会社説明をしてほしい<br>
                        ・学校に対して自社の会社説明をしたい<br>
                        ・講演についてのお問合せ<br>
                        などの希望があれば、お問合せフォームを通じて事務局に連絡してください。</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <!-- contents01 -->

                <div id="contents02" class="contactContents02" v-if="inquiryType == 'WEB商談申込'">

                    <div class="contactContents02Intro">
                        <p>おかやまテクノロジー展2024〈ONLINE〉には様々なニーズに対応できる多くの企業が出展しています。<br>
                        「部品の供給元を探している」「仕事を手伝ってくれる先を探している」などのニーズがあれば、登録してください。<br>
                        WEB商談会は、ZOOM等のオンラインツールにて行います。 下記項目をご記入いただき、送信ボタンを押してください。 担当者から直接ご連絡させていただきます。</p>
                    </div>

                    <div class="contactContents02Flow">
                        <picture>
                        <source media="(min-width: 961px)" srcset="/assets/img/contact/imgFlow.svg">
                        <source media="(max-width: 960px)" srcset="/assets/img/contact/imgFlowSp.svg">
                        <img src="/assets/img/contact/imgFlow.svg" alt="Step1 登録フォームのご記入 Step2 企業・財団に通知 Step3 企業担当者より直接ご連絡 Step4 WEB商談会開始" />
                        </picture>
                    </div>

                    <table>
                        <tbody>
                        <tr>
                            <th><span class="must">必須</span><span>ご希望日時</span></th>
                            <td>
                            <input id="gokibounichiji" type="text" name="ご希望日時" placeholder="※ 候補日を複数記入" v-model="formData.negotiations_date">
                            </td>
                        </tr>
                        <tr>
                            <th><span class="must">必須</span><span>ご希望形式</span></th>
                            <td>
                            <input id="gokiboukeishiki" type="text" name="ご希望形式" placeholder="例 &#41; Zoom,Teams" v-model="formData.negotiations_type">
                            </td>
                        </tr>
                        <tr>
                            <th><span class="must">必須</span><span>商談希望内容</span></th>
                            <td>
                            <textarea id="shodankibounaiyou" rows="8" name="商談希望内容" placeholder="商談に関する内容を入力" v-model="formData.content"></textarea>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="contactContents02Outro">
                        <p>※ご入力いただいた個人情報は、<br class="pcNone">本ブース担当者へ通知されます。</p>
                    </div>

                </div>
                <!-- contents02 -->

                <div class="contactFormPrivacy">
                    <p>ご入力いただいた情報は(公財)岡山県産業振興財団で管理し、当財団が開催する展示会・ 商談会等の案内以外の目的で使用しません。また、第三者には提供しません。
                    ご質問に対する回答は、お問い合わせいただいた方の特定のご質問にお答えするものです。回答者の許可なく、回答内容の一部もしくは全体を転用、二次利用、または当該質問者以外に開示することは固くお断りします。
                    ※メールソフトの設定により、迷惑メールフォルダやごみ箱に振り分けられる可能性があります。受信しない場合はそちらもご確認ください。</p>
                    <p class="contactFormPrivacyLink"><a href="https://www.optic.or.jp/contents/display/privacy.html" target="_blank" rel="noopener">(公財)岡山県産業振興財団<br class="pcNone">公式HPプライバシーポリシーはこちら</a></p>
                </div>
                <div class="contactFormOutro">
                    <vue-element-loading :active="isWaitingToBeSent" color="black" spinner="spinner" />
                    <input class="contactFormOutroBtn" type="submit" value="送信する">
                </div>

            </form>
        </div>


    </main>
    <!-- #pageID -->
</template>

<script setup>
    import { ref, onMounted, inject, computed, watch, reactive } from 'vue';
    import Splide from '@splidejs/splide';
    import { onBeforeRouteLeave,useRoute, useRouter } from 'vue-router';
    const $axios = inject('$axios');
    import { useToast } from "vue-toastification";
    const toast = useToast();
    import VueElementLoading from "vue-element-loading";

    const route = useRoute();
    const company = ref([]);
    const currentUser = computed(() => store.state.currentUser);
    const queryCompanyId = computed(() => route.query.company_id);

    import { useStore } from 'vuex';
    //store/index.jsのグローバルデータ
    const store = useStore();
    const router = useRouter();

    const showVideoModalFlag = ref(false);
    const videoLoadingFlag = ref(false);
    const pickupCompanies = ref([]);
    const isWaitingToBeSent = ref(false);
    const officeFlag = ref(true);
    const inquiryType = ref('お問い合わせ');
    const formData = reactive({});
    
    watch(queryCompanyId, (newValue, oldValue) => {
        //newValueに新しい値が入る　もし定義されていない（ヘッダーのお問合せにアクセスしたら）
        //事務局用の表示へ変更
        if (!newValue) {
            officeFlag.value = true;
            inquiryType.value = 'お問い合わせ';
            formData.content = '';
        }
    });

    console.log(currentUser.value);

    const setContactEnv = () => {
        const radioButtons = document.querySelectorAll('.contactKibou');
        const content1 = document.getElementById('contents01');
        const content1_textarea = document.getElementById('otoiawasenaiyou');

        const content2 = document.getElementById('contents02');
        const content2_input01 = document.getElementById('gokibounichiji');
        const content2_input02 = document.getElementById('gokiboukeishiki');
        const content2_input03 = document.getElementById('shodankibounaiyou');


        // ラジオボタンの変更イベントを監視
        radioButtons.forEach((radio) => {
            radio.addEventListener('change', function () {
                if (this.value === 'お問い合わせ') {
                content1.style.display = 'block';
                content2.style.display = 'none';

                content01_set();
                content02_remove();

                } else if (this.value === 'WEB商談申込') {
                content1.style.display = 'none';
                content2.style.display = 'block';

                content01_remove();
                content02_set();

                }
            });
        });

        function content01_set() {
            content1_textarea.setAttribute('required', 'required');
        }
        function content01_remove() {
            content1_textarea.removeAttribute('required');
        }

        function content02_set() {
            content2_input01.setAttribute('required', 'required');
            content2_input02.setAttribute('required', 'required');
            content2_input03.setAttribute('required', 'required');
        }
        function content02_remove() {
            content2_input01.removeAttribute('required');
            content2_input02.removeAttribute('required');
            content2_input03.removeAttribute('required');
        }

        //企業のIDがクエリにあるか確認
        if (route.query.company_id) {
            console.log('企業IDは'+route.query.company_id);
            officeFlag.value = false;
        }

    }

    const sendForm = () => {

        isWaitingToBeSent.value = true;

        switch (inquiryType.value) {
            case 'お問い合わせ':
                
                if (officeFlag.value == false) {
                    //企業のお問合せメール
                    $axios.post('/api/inquiry/company/'+queryCompanyId.value, formData)
                        .then((res) => {
                            console.log(res);
                            if (res.status == 200) {
                                //alert('送信完了');
                                router.push('/contactThanks');
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(()=>{
                            isWaitingToBeSent.value = false;
                        })
                } else {
                    //事務局のお問合せメール
                    $axios.post('/api/inquiry/organizer',formData)
                        .then((res) => {
                            console.log(res);
                            if (res.status == 200) {
                                //alert('送信完了');
                                router.push('/contactThanks');
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(()=>{
                            isWaitingToBeSent.value = false;
                        })
                }
                break;

            case 'WEB商談申込':
                //企業のWEB商談申込メール
                $axios.post('/api/negotiation/'+queryCompanyId.value, formData)
                    .then((res) => {
                        console.log(res);
                        if (res.status == 200) {
                            //alert('送信完了');
                            router.push('/contactThanks');
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(()=>{
                        isWaitingToBeSent.value = false;
                    })
                break;
        
            default:
                break;
        }
    }

    // mounted
    onMounted(() => {
        setContactEnv();
    });
</script>
