<template>
    <main id="mypage" class="wrapper wrapperBd">

        <div class="liPankuzu">
            <ul>
                <li><router-link to="/">ホーム</router-link></li>
                <li>マイページ</li>
            </ul>
        </div>
        <!-- li-pankuzu -->

        <section class="secMainview01">
            <div class="secMainview01Inner container containerLarge">
                <h1 class="secMainview01Title">
                    <img src="/assets/img/common/txtMypage.svg" alt="マイページ" class="secMainview01Jp" />
                </h1>
                <div class="effectBlock effectBlock04"></div>
            </div>
        </section>
        <!-- secMainview01 -->

        <div class="container">

            <div class="mypageUserinfo">
                <p><span>{{currentUser.name}}</span>様</p>
                <p><a href="javascript:void(0)" class="linkIcon btnLogout" @click="logout"><img src="/assets/img/common/iconLogout.svg" alt="" />ログアウト</a></p>
            </div>

            <div class="mypageUserbox tabBox">
                <ul class="tabBoxTabGroup">
                <li v-bind:class="['tabBoxTab','tabA', store.state.currentMyPageShowTabType == 'recommendations' ? 'active' : '']" @click="switchCurrentMyPageShowTabType('recommendations')">
                    <span>
                    <picture>
                        <source media="(min-width: 961px)" srcset="/assets/img/common/txtYourRecommend.svg">
                        <source media="(max-width: 960px)" srcset="/assets/img/common/txtYourRecommendSp.svg">
                        <img src="/assets/img/common/txtYourRecommend.svg" alt="あなたへのおすすめ企業">
                    </picture>
                    </span>
                </li>
                <li v-bind:class="['tabBoxTab','tabB', store.state.currentMyPageShowTabType == 'likes' ? 'active' : '']" @click="switchCurrentMyPageShowTabType('likes')">
                    <span>
                    <picture>
                        <source media="(min-width: 961px)" srcset="/assets/img/common/txtOkiniiri.svg">
                        <source media="(max-width: 960px)" srcset="/assets/img/common/txtOkiniiriSp.svg">
                        <img src="/assets/img/common/txtOkiniiri.svg" alt="お気に入り企業">
                    </picture>
                    </span>
                </li>
                </ul>
                <div class="tabBoxPanelGroup">
                <div v-bind:class="['tabBoxPanel','tabA', store.state.currentMyPageShowTabType == 'recommendations' ? 'show' : '']">
                    <p>あなたの閲覧履歴の傾向から、おすすめ企業をご紹介いたします。</p>
                    <ul>
                        <li v-for="recommendation in recommendations" :key="recommendation.id">
                            <router-link :to="`/companyDetail?company_id=${recommendation.id}`" v-bind:class="['linkCompanycard', recommendation.panel_color]" >
                                <div class="linkCompanycardImg">
                                    <img v-if="recommendation.background_image_path" :src="recommendation.background_image_path" alt="" @error="altImg">
                                    <img v-else src="/assets/img/top/pickupThumb01.jpg" alt="{企業名}" />
                                </div>
                                <span class="linkCompanycardTag">{{recommendation.industry_name}}</span>
                                <span class="linkCompanycardTitle"><em>{{recommendation.company_name}}</em></span>
                                <span class="linkCompanycardExc">{{recommendation.business_content}}</span>
                            </router-link>
                        </li>
                        <!--
                        <li>
                            <a href="/" class="linkCompanycard cat1 show">
                            <div class="linkCompanycardImg"><img src="/assets/img/top/pickupThumb01.jpg" alt="" /></div>
                            <span class="linkCompanycardTag">タグ名が1点入ります</span>
                            <span class="linkCompanycardTitle"><em>株式会社◯◯◯◯◯</em></span>
                            </a>
                        </li>
                        -->
                    </ul>
                </div>
                <div v-bind:class="['tabBoxPanel','tabB', store.state.currentMyPageShowTabType == 'likes' ? 'show' : '']">
                    <p>お気に入りに登録した企業をご確認いただけます。</p>
                    <ul>
                        <li v-for="like in likes" :key="like.id">
                            <router-link :to="`/companyDetail?company_id=${like.id}`" v-bind:class="['linkCompanycard', like.panel_color]" >
                                <div class="linkCompanycardImg">
                                    <img v-if="like.background_image_path" :src="like.background_image_path" alt="" @error="altImg">
                                    <img v-else src="/assets/img/top/pickupThumb01.jpg" alt="{企業名}" />
                                </div>
                                <span class="linkCompanycardTag">{{like.industry_name}}</span>
                                <span class="linkCompanycardTitle"><em>{{like.company_name}}</em></span>
                                <span class="linkCompanycardExc">{{like.business_content}}</span>
                            </router-link>
                        </li>
                        <!--
                        <li>
                            <a href="/" class="linkCompanycard cat1">
                            <div class="linkCompanycardImg"><img src="/assets/img/top/pickupThumb01.jpg" alt="" /></div>
                            <span class="linkCompanycardTag">タグ名が1点入ります</span>
                            <span class="linkCompanycardTitle"><em>株式会社◯◯◯◯◯</em></span>
                            </a>
                        </li>
                        -->
                    </ul>
                </div>
            </div>
        </div>

        <div class="mypageOutro">
            <ul>
                <li><label class="btnViewmore btnViewmoreK" for="btnRecommend"><button id="btnRecommend" class="btnViewmoreInner" type="button">あなたへのおすすめ企業を見る</button></label></li>
                <li><label class="btnViewmore btnViewmoreK" for="btnFavorite"><button id="btnFavorite" class="btnViewmoreInner" type="button">お気に入り企業を見る</button></label></li>
            </ul>
        </div>

    </div>

    </main>
    <!-- #pageID -->

</template>

<script setup>
    import { ref, onMounted, inject, computed } from 'vue';
    import Splide from '@splidejs/splide';
    import { onBeforeRouteLeave,useRoute, useRouter } from 'vue-router';
    const $axios = inject('$axios');
    import { useToast } from "vue-toastification";
    const toast = useToast();
    import VueElementLoading from "vue-element-loading";

    const route = useRoute();
    const recommendations = ref([]);
    const likes = ref([]);
    const currentUser = computed(() => store.state.currentUser);

    import { useStore } from 'vuex';
    //store/index.jsのグローバルデータ
    const store = useStore();
    const router = useRouter();

    const showVideoModalFlag = ref(false);
    const videoLoadingFlag = ref(false);
    const pickupCompanies = ref([]);
    const dataLoading = ref(true);

    console.log(currentUser.value);

    //関数
    //会社情報を取得
    const fetchMyPageContent = () => {
        $axios.get('/api/get_my_page_content',
            {params:
                {
                    user_id: currentUser.value.id
                }
            })
            .then((res) => {
                console.log(res);
                recommendations.value = res.data.recommendations;
                likes.value = res.data.likes;
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(()=>{
                setMyPageTabEnv();
                dataLoading.value = false;
            });
    }

    //ログアウト
    const logout = () => {
        $axios.post('/api/logout')
        .then((res) => {
            console.log(res);
            //storeの値変更
            store.commit('setAuthFlag', false);
            store.commit('setCurrentUser', '');
            sessionStorage.removeItem('unAuthReloadFlag');
            //toast("ログアウトしました!", {
            //    position: "top-left",
            //    timeout: 1000,
            //    closeOnClick: true,
            //    pauseOnFocusLoss: true,
            //    pauseOnHover: true,
            //    draggable: true,
            //    draggablePercent: 0.6,
            //    showCloseButtonOnHover: false,
            //    hideProgressBar: true,
            //    closeButton: "button",
            //    icon: true,
            //    rtl: false
            //});
            setTimeout(() => {
                router.push('/');
            }, 200);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const altImg = (event) => {
        event.target.src = "/assets/img/top/pickupThumb01.jpg";
    }

    const switchCurrentMyPageShowTabType = (type) => {
        store.commit('setCurrentMyPageShowTabType', type);
    }

    // タブ切り替え
    const setMyPageTabEnv = () => {
        const tabs = document.getElementsByClassName("tabBoxTab");
        const tabPanels = document.getElementsByClassName("tabBoxPanel");
        const tabBtn01 = document.getElementById("btnRecommend");
        const tabBtn02 = document.getElementById("btnFavorite");
        //タブボタンでの切り替え
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].addEventListener("click", tabSwitch, false);
        }
        function tabSwitch() {
            document
                .querySelectorAll(".tabBoxTab.active")[0]
                .classList.remove("active");
            this.classList.add("active");
            document
                .querySelectorAll(".tabBoxPanel.show")[0]
                .classList.remove("show");
            const arrayTabs = Array.prototype.slice.call(tabs);
            const index = arrayTabs.indexOf(this);
            const tabPanel = document.getElementsByClassName("tabBoxPanel");
            tabPanel[index].classList.add("show");
            //ボタン表示の切り替え
            if (tabPanel[0].classList.contains("show")) {
                tabBtn01.classList.remove("active");
                tabBtn02.classList.add("active");
            }
            if (tabPanel[1].classList.contains("show")) {
                tabBtn01.classList.add("active");
                tabBtn02.classList.remove("active");
            }
        }
        //ボタンによるタブ切り替え
        tabBtn01.addEventListener("click", tabSwitchBtn);
        tabBtn02.addEventListener("click", tabSwitchBtn);
        function tabSwitchBtn(event) {
            let id = event.target.id;
            // console.log(id);
            if (id == "btnRecommend") {
                tabBtn01.classList.remove("active");
                tabBtn02.classList.add("active");
                tabPanels[1].classList.remove("show");
                tabs[1].classList.remove("active");
                tabPanels[0].classList.add("show");
                tabs[0].classList.add("active");
            }
            if (id == "btnFavorite") {
                tabBtn01.classList.add("active");
                tabBtn02.classList.remove("active");
                tabPanels[0].classList.remove("show");
                tabs[0].classList.remove("active");
                tabPanels[1].classList.add("show");
                tabs[1].classList.add("active");
            }
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
        //ページ読み込み時のボタン初期表示
        tabBtnDisplay();
        function tabBtnDisplay() {
            if (tabPanels[0].classList.contains("show")) {
                tabBtn01.classList.remove("active");
                tabBtn02.classList.add("active");
            }
            if (tabPanels[1].classList.contains("show")) {
                tabBtn01.classList.add("active");
                tabBtn02.classList.remove("active");
            }
        }
    }

    // mounted
    onMounted(() => {
        
        fetchMyPageContent();
    });
</script>
