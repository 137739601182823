<template>
    <body class="login">

    <main id="presentation" class="wrapper wrapperBd">

    <div class="liPankuzu">
      <ul>
        <li><router-link to="/">ホーム</router-link></li>
        <li>出展者プレゼンテーション</li>
      </ul>
    </div>
    <!-- li-pankuzu -->

    <section class="secMainview01">
      <div class="secMainview01Inner container containerLarge">
        <h1 class="secMainview01Title">
            <img src="/assets/img/common/txtShuttensya.svg" alt="出展者プレゼンテーション" class="secMainview01Jp" />
        </h1>
        <div class="effectBlock effectBlock04"></div>
        <div class="effectBlock effectBlock01"></div>
      </div>
    </section>
    <!-- secMainview01 -->

    <div class="presentationListview container containerLarge">
      <div class="presentationListviewListarea">
        <ul>
          <li @click="fetchPresentationDetail(presentation.id)" v-for="presentation in presentations" :key="presentation.id">
            <a v-bind:class="['linkPresentation', presentation.panel_color]">
              <div class="linkPresentationInner">
                <span class="linkPresentationTitle">{{presentation.title}}</span>
                <span v-bind:class="['linkPresentationTag', presentation.panel_color]">{{presentation.industry_name}}</span>
                <span class="linkPresentationTxt">{{presentation.company_name}}</span>
                <div class="linkPresentationTri linkPresentationTriLeft"></div>
                <div class="linkPresentationTri linkPresentationTriRight"></div>
              </div>
            </a>
          </li>
          <!--
          <li>
            <a class="linkPresentation cat2">
              <div class="linkPresentationInner">
                <span class="linkPresentationTitle">この文章はサンプルです。</span>
                <span class="linkPresentationTag">プレス・板金</span>
                <span class="linkPresentationTxt">株式会社◯◯◯◯◯ ヤマダタロウ 氏</span>
                <div class="linkPresentationTri linkPresentationTriLeft"></div>
                <div class="linkPresentationTri linkPresentationTriRight"></div>
              </div>
            </a>
          </li>
          -->
        </ul>
      </div>
    </div>
    <!-- listView -->

    <div class="modal active" v-if="showVideoModalFlag">
      <div class="modalWrapper">
        <div class="modalInner">
          <div class="modalClose" @click="hideVideoModal"><img src="/assets/img/common/iconPopupClose.svg" alt="閉じる" width="31" height="31"></div>
          <!-- contents -->
            <vue-element-loading :active="videoLoadingFlag" color="black" spinner="spinner" />
            <iframe @load="onIframeLoad" width="560" height="315" :src="videoUrl(selectedVideoUrl)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <!-- contents -->
        </div>
        <!-- inner -->
      </div>
      <div class="modalBg"></div>
    </div>
    <!-- modal active付与で表示切り替え -->

  </main>
  <!-- #pageID -->
</body>

</template>

<script setup>
  import { ref, onMounted, inject, watch, computed, onBeforeMount } from 'vue';
  import { onBeforeRouteLeave, useRoute } from 'vue-router';
  import router from '@/router';
  import axios from 'axios';
  import VueElementLoading from "vue-element-loading";
  import { useStore } from 'vuex';
  const route = useRoute();
  //store/index.jsのグローバルデータ
  const store = useStore();

  //main.jsで定義したグローバルaxios
  const $axios = inject('$axios');

  const authFlag = computed(() => store.state.authFlag);

  const presentations = ref([]);
  const presentationDetail = ref([]);
  const showVideoModalFlag = ref(false);
  const videoLoadingFlag = ref(false);
  const selectedVideoUrl = ref('');

  //基調講演の取得
  const fetchPresentations = () => {
    $axios.get('/api/get_presentations')
      .then(res => {
        console.log(res.data.presentations);
        presentations.value = res.data.presentations;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
      })
  }

  const fetchPresentationDetail = (id) => {

    //まだログインしてない時はログインページへ飛ばす
    if (authFlag.value == false) {
      store.commit('setTopPagePresentationFlagNum', id);
      //ログイン後にここに戻って来れるようにパスを保存
      sessionStorage.setItem('failedPath', route.fullPath);
      return router.push('/login');
    }

    $axios.get('/api/get_presentation_detail?presentation_id='+id)
      .then((res) => {
          console.log(res);
          presentationDetail.value = res.data.presentation_detail;
      })
      .catch((err) => {
          console.log(err);
      })
      .finally(()=>{
        selectedVideoUrl.value = presentationDetail.value.video_url;
        showVideoModal();
        gtagViewPresentation(presentationDetail.value.title);
      });
  }

  const showVideoModal = () => {
    showVideoModalFlag.value = true;
    videoLoadingFlag.value = true;
  }

  const hideVideoModal = () => {
    showVideoModalFlag.value = false;
  }

  const onIframeLoad = () => {
    videoLoadingFlag.value = false;
  }

  const videoUrl = (video_url) => {
    console.log(video_url);
    const array = video_url.split('/');
    let youTubeId = array[array.length - 1];
    youTubeId = youTubeId.replace('watch?v=', ''); //watch?v=を削除
    console.log(youTubeId);
    return `https://www.youtube.com/embed/${youTubeId}`;
  }

  //トップページに表示されているプレゼンテーションを押した時に発火 (格納されているidを渡して情報を取得後モーダル出現)
  const showTopPagePresentation = () => {
    if (store.state.topPagePresentationFlagNum) {


      //topから来てまだログインしてない時はログインページへ飛ばす
      if (store.state.unAuthLinkFromTopPageFlag == true && store.state.authFlag == false) {
        //ログイン後にここに戻って来れるようにパスを保存
        sessionStorage.setItem('failedPath', route.fullPath);
        return router.push('/login');
      }

      //まだログインしてない時はモーダル開けない trueすなわち認証している場合のみ発火
      if (authFlag.value == true) {
        fetchPresentationDetail(store.state.topPagePresentationFlagNum);
      }

      //値をリセット
      store.commit('resetTopPagePresentationFlagNum');
    }
  }

  //プレゼンテーション視聴
  const gtagViewPresentation = (title) => {
    const label = title;
    gtag('event', "custom_user_event", {
      'event_category': "view_presentation_video",
      'event_label': label,
      'value' : 1
    });
  }

  //mountedより前に発火
	onBeforeMount(() => {
    fetchPresentations();
    showTopPagePresentation();
  })
  
</script>
