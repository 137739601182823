<template>
    <main id="enquete" class="wrapper wrapperBd">

        <div class="liPankuzu">
        <ul>
            <li><router-link to="/">ホーム</router-link></li>
            <li>アンケート</li>
        </ul>
        </div>
        <!-- li-pankuzu -->

        <section class="secMainview01">
        <div class="secMainview01Inner container containerLarge">
            <h1 class="secMainview01Title">
                <img src="/assets/img/common/txtEnquete.svg" alt="アンケート" class="secMainview01Jp" />
            </h1>
            <div class="effectBlock effectBlock04"></div>
        </div>
        </section>
        <!-- secMainview01 -->

        <div class="contactIntro container">
        <p>今後の運営・サービス向上のために皆様のご意見等を頂戴したく、<br class="spNone">今回のオンライン展示会について、アンケートのご協力をお願いします。</p>
        <div class="effectBlock effectBlock05"></div>
        </div>


        <div class="enqueteForm container">
            <form @submit.prevent="sendForm">

                <dl>
                <dt>来場目的は? <small>(複数回答可)</small></dt>
                <dd>
                    <ul>
                        <li><label class="checkbox2" for="raijo01"><input id="raijo01" type="checkbox" name="来場目的" value="新しい取引先の開拓" v-model="formData.purpose_of_visit"><span>新しい取引先の開拓</span></label></li>
                        <li><label class="checkbox2" for="raijo02"><input id="raijo02" type="checkbox" name="来場目的" value="新しい技術等を探す" v-model="formData.purpose_of_visit"><span>新しい技術等を探す</span></label></li>
                        <li><label class="checkbox2" for="raijo03"><input id="raijo03" type="checkbox" name="来場目的" value="業界動向の情報収集" v-model="formData.purpose_of_visit"><span>業界動向の情報収集</span></label></li>
                        <li><label class="checkbox2" for="raijo04"><input id="raijo04" type="checkbox" name="来場目的" value="リクルート" v-model="formData.purpose_of_visit"><span>リクルート</span></label></li>
                        <li><label class="checkbox2" for="raijo05"><input id="raijo05" type="checkbox" name="来場目的" value="その他" v-model="formData.purpose_of_visit"><span>その他</span></label></li>
                    </ul>
                    <p>その他を選んだ方は下記へご記入ください。</p>
                    <textarea id="raijo06" rows="8" name="来場目的 その他" placeholder="その他の方はこちらへご記入ください" v-model="formData.etc_purpose_of_visit"></textarea>
                </dd>
                </dl>

                <dl>
                <dt>おかやまテクノロジー展2024〈ONLINE〉で利用したものは?<br class="pcNone"><small>(複数回答可)</small></dt>
                <dd>
                    <ul>
                    <li><label class="checkbox2" for="riyo01"><input id="riyo01" type="checkbox" name="ONLINEで利用したもの" value="出展企業ブース" v-model="formData.used_function"><span>出展企業ブース</span></label></li>
                    <li><label class="checkbox2" for="riyo02"><input id="riyo02" type="checkbox" name="ONLINEで利用したもの" value="講演" v-model="formData.used_function"><span>講演</span></label></li>
                    <li><label class="checkbox2" for="riyo03"><input id="riyo03" type="checkbox" name="ONLINEで利用したもの" value="出展者プレゼンテーション" v-model="formData.used_function"><span>出展者プレゼンテーション</span></label></li>
                    <li><label class="checkbox2" for="riyo04"><input id="riyo04" type="checkbox" name="ONLINEで利用したもの" value="WEB商談登録" v-model="formData.used_function"><span>WEB商談登録</span></label></li>
                    <li><label class="checkbox2" for="riyo05"><input id="riyo05" type="checkbox" name="ONLINEで利用したもの" value="名刺交換機能" v-model="formData.used_function"><span>名刺交換機能</span></label></li>
                    <li><label class="checkbox2" for="riyo06"><input id="riyo06" type="checkbox" name="ONLINEで利用したもの" value="その他" v-model="formData.used_function"><span>その他</span></label></li>
                    </ul>
                    <p>その他を選んだ方は下記へご記入ください。</p>
                    <textarea id="riyo07" rows="8" name="ONLINEで利用したもの その他" placeholder="その他の方はこちらへご記入ください" v-model="formData.etc_used_function"></textarea>
                </dd>
                </dl>

                <dl>
                <dt>来場したきっかけは?<br class="pcNone"><small>(複数回答可)</small></dt>
                <dd>
                    <ul class="enqueteFormHalfSp">
                    <li><label class="checkbox2" for="kikkake01"><input id="kikkake01" type="checkbox" name="来場したきっかけ" value="新聞" v-model="formData.chance_to_visit"><span>新聞</span></label></li>
                    <li><label class="checkbox2" for="kikkake02"><input id="kikkake02" type="checkbox" name="来場したきっかけ" value="雑誌" v-model="formData.chance_to_visit"><span>雑誌</span></label></li>
                    <li><label class="checkbox2" for="kikkake03"><input id="kikkake03" type="checkbox" name="来場したきっかけ" value="ラジオ" v-model="formData.chance_to_visit"><span>ラジオ</span></label></li>
                    <li><label class="checkbox2" for="kikkake04"><input id="kikkake04" type="checkbox" name="来場したきっかけ" value="テレビ" v-model="formData.chance_to_visit"><span>テレビ</span></label></li>
                    <li><label class="checkbox2" for="kikkake05"><input id="kikkake05" type="checkbox" name="来場したきっかけ" value="公式ホームページ" v-model="formData.chance_to_visit"><span>公式ホームページ</span></label></li>
                    <li><label class="checkbox2" for="kikkake06"><input id="kikkake06" type="checkbox" name="来場したきっかけ" value="Facebook" v-model="formData.chance_to_visit"><span>Facebook</span></label></li>
                    <li><label class="checkbox2" for="kikkake07"><input id="kikkake07" type="checkbox" name="来場したきっかけ" value="メルマガ" v-model="formData.chance_to_visit"><span>メルマガ</span></label></li>
                    <li><label class="checkbox2" for="kikkake08"><input id="kikkake08" type="checkbox" name="来場したきっかけ" value="ハガキ" v-model="formData.chance_to_visit"><span>ハガキ</span></label></li>
                    <li><label class="checkbox2" for="kikkake09"><input id="kikkake09" type="checkbox" name="来場したきっかけ" value="チラシ" v-model="formData.chance_to_visit"><span>チラシ</span></label></li>
                    <li><label class="checkbox2" for="kikkake10"><input id="kikkake10" type="checkbox" name="来場したきっかけ" value="ポスター" v-model="formData.chance_to_visit"><span>ポスター</span></label></li>
                    <li><label class="checkbox2" for="kikkake11"><input id="kikkake11" type="checkbox" name="来場したきっかけ" value="取引先から" v-model="formData.chance_to_visit"><span>取引先から</span></label></li>
                    <li><label class="checkbox2" for="kikkake12"><input id="kikkake12" type="checkbox" name="来場したきっかけ" value="岡山駅電子看板" v-model="formData.chance_to_visit"><span>岡山駅電子看板</span></label></li>
                    <li><label class="checkbox2" for="kikkake13"><input id="kikkake13" type="checkbox" name="来場したきっかけ" value="その他" v-model="formData.chance_to_visit"><span>その他</span></label></li>
                    </ul>
                    <p>その他を選んだ方は下記へご記入ください。</p>
                    <textarea id="kikkake14" rows="8" name="来場したきっかけ その他" placeholder="その他の方はこちらへご記入ください" v-model="formData.etc_chance_to_visit"></textarea>
                </dd>
                </dl>

                <dl>
                <dt>閲覧環境は?</dt>
                <dd>
                    <ul>
                    <li><label class="checkbox2" for="kaihatsu01"><input id="kaihatsu01" type="checkbox" name="閲覧環境" value="PC" v-model="formData.environment_to_see"><span>PC</span></label></li>
                    <li><label class="checkbox2" for="kaihatsu02"><input id="kaihatsu02" type="checkbox" name="閲覧環境" value="スマートフォン" v-model="formData.environment_to_see"><span>スマートフォン</span></label></li>
                    <li><label class="checkbox2" for="kaihatsu03"><input id="kaihatsu03" type="checkbox" name="閲覧環境" value="タブレット" v-model="formData.environment_to_see"><span>タブレット</span></label></li>
                    </ul>
                    <p>追加してほしい機能は?</p>
                    <textarea id="kaihatsu05" rows="8" name="閲覧環境 その他" placeholder="追加してほしい機能をご記入ください" v-model="formData.etc_environment_to_see"></textarea>
                </dd>
                </dl>

                <dl>
                <dt>機能改善が必要なものは?<br class="pcNone"><small>(複数回答可)</small></dt>
                <dd>
                    <ul class="enqueteFormHalf">
                    <li><label class="checkbox2" for="kinoukaizen01"><input id="kinoukaizen01" type="checkbox" name="機能改善" value="出展企業ブース内容" v-model="formData.improvement"><span>出展企業ブース内容</span></label></li>
                    <li><label class="checkbox2" for="kinoukaizen02"><input id="kinoukaizen02" type="checkbox" name="機能改善" value="出展企業ブースの見やすさ" v-model="formData.improvement"><span>出展企業ブースの見やすさ</span></label></li>
                    <li><label class="checkbox2" for="kinoukaizen03"><input id="kinoukaizen03" type="checkbox" name="機能改善" value="講演・出展者プレゼンテーションの見やすさ" v-model="formData.improvement"><span>講演・出展者プレゼンテーションの見やすさ</span></label></li>
                    <li><label class="checkbox2" for="kinoukaizen04"><input id="kinoukaizen04" type="checkbox" name="機能改善" value="企業検索の使い方" v-model="formData.improvement"><span>企業検索の使い方</span></label></li>
                    <li><label class="checkbox2" for="kinoukaizen05"><input id="kinoukaizen05" type="checkbox" name="機能改善" value="WEB商談登録の使い方" v-model="formData.improvement"><span>WEB商談登録の使い方</span></label></li>
                    <li><label class="checkbox2" for="kinoukaizen06"><input id="kinoukaizen06" type="checkbox" name="機能改善" value="出展者問合せフォームの使い方" v-model="formData.improvement"><span>出展者問合せフォームの使い方</span></label></li>
                    <li><label class="checkbox2" for="kinoukaizen07"><input id="kinoukaizen07" type="checkbox" name="機能改善" value="その他" v-model="formData.improvement"><span>その他</span></label></li>
                    </ul>
                    <p>チェックした項目の具体的な内容をご記入ください</p>
                    <textarea id="kinoukaizen08" rows="8" name="機能改善 具体的な内容" placeholder="チェックした項目の具体的な内容をご記入ください" v-model="formData.etc_improvement"></textarea>
                </dd>
                </dl>

                <dl>
                <dt>ご意見・ご感想</dt>
                <dd>
                    <p>今後の運営・サービス向上のため、お気づきの点やご意見等をお聞かせください。</p>
                    <textarea id="goiken" rows="8" name="ご意見・ご感想" placeholder=""  v-model="formData.etc_comment"></textarea>
                </dd>
                </dl>

                <div class="enqueteFormOutro">
                    <p><b>ご協力ありがとうございました。<br>今後ともおかやまテクノロジー展を<br class="pcNone">宜しくお願いいたします。</b></p>
                    <p><small>おかやまテクノロジー展事務局</small></p>
                </div>

                <div class="contactFormPrivacy">
                    <p>ご入力いただいた情報は(公財)岡山県産業振興財団で管理し、当財団が開催する展示会・ 商談会等の案内以外の目的で使用しません。また、第三者には提供しません。
                    ご質問に対する回答は、お問い合わせいただいた方の特定のご質問にお答えするものです。回答者の許可なく、回答内容の一部もしくは全体を転用、二次利用、または当該質問者以外に開示することは固くお断りします。
                    ※メールソフトの設定により、迷惑メールフォルダやごみ箱に振り分けられる可能性があります。受信しない場合はそちらもご確認ください。</p>
                    <p class="contactFormPrivacyLink"><a href="https://www.optic.or.jp/contents/display/privacy.html" target="_blank" rel="noopener">(公財)岡山県産業振興財団<br class="pcNone">公式HPプライバシーポリシーはこちら</a></p>
                </div>
                <div class="contactFormOutro">
                    <vue-element-loading :active="isWaitingToBeSent" color="black" spinner="spinner" />
                    <input class="contactFormOutroBtn" type="submit" value="送信する">
                </div>

            </form>
        </div>


    </main>
    <!-- #pageID -->

</template>

<script setup>
    import { ref, onMounted, inject, computed, reactive } from 'vue';
    import Splide from '@splidejs/splide';
    import { onBeforeRouteLeave,useRoute, useRouter } from 'vue-router';
    const $axios = inject('$axios');
    import { useToast } from "vue-toastification";
    const toast = useToast();
    import VueElementLoading from "vue-element-loading";

    const route = useRoute();
    const company = ref([]);
    const currentUser = computed(() => store.state.currentUser);

    import { useStore } from 'vuex';
    //store/index.jsのグローバルデータ
    const store = useStore();
    const router = useRouter();

    const showVideoModalFlag = ref(false);
    const videoLoadingFlag = ref(false);
    const pickupCompanies = ref([]);
    const isWaitingToBeSent = ref(false);
    const formData = reactive({
        'purpose_of_visit': [],
        'etc_purpose_of_visit': '',
        'chance_to_visit': [],
        'etc_chance_of_visit': '',
        'used_function': [],
        'etc_used_function': '',
        'environment_to_see': [],
        'etc_environment_to_see': '',
        'improvement': [],
        'etc_improvement': '',
        'etc_comment': ''
    });

    console.log(currentUser.value);

        const sendForm = () => {

        isWaitingToBeSent.value = true;
                
        //企業のお問合せメール
        $axios.post('/api/questionnaire', formData)
            .then((res) => {
                console.log(res);
                if (res.status == 200) {
                    //alert('送信完了');
                    router.push('/enqueteThanks');
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(()=>{
                isWaitingToBeSent.value = false;
            })
    }


    // mounted
    onMounted(() => {

    });
</script>
