import axios from 'axios';

let apiPath = '';
switch (process.env.VUE_APP_ENV) {
  case 'local':
    apiPath = 'http://localhost:8000';
    break;
  case 'stg':
    apiPath ='/cms';
    break;
  case 'prod':
    apiPath ='/cms';
    break;
  default:
    break;
}

const axiosInstance = axios.create({
  baseURL: apiPath, // APIのベースURLをここに設定
  withCredentials: true, // CORS対策
  // その他のAxiosのオプションをここに追加
});

// プラグインオブジェクトを作成 OptionsAPIなら必要
//const axiosPlugin = {
//  install(app) {
//    app.config.globalProperties.$axios = axiosInstance;
//  },
//};

export default axiosInstance;
