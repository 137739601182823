import { createRouter, createWebHistory } from "vue-router";
import TopView from "../views/pages/TopView.vue";
import BoardView from "../views/pages/BoardView.vue";
import CompanyView from "../views/pages/CompanyView.vue";
import CompanyDetailView from "../views/pages/CompanyDetailView.vue";
import PresentationView from "../views/pages/PresentationView.vue";
import LectureView from "../views/pages/LectureView.vue";
import LoginView from "../views/pages/LoginView.vue";
import MyPageView from "../views/pages/MyPageView.vue";
import EnqueteThanksView from "../views/pages/EnqueteThanks.vue";
import ContactThanksView from "../views/pages/ContactThanks.vue";
import EnqueteIndexView from "../views/pages/EnqueteIndex.vue";
import ContactIndexView from "../views/pages/ContactIndex.vue";
import createStoreInstance from "../store/index";
import axiosPlugin from '../plugins/axiosPlugin'; 
//navigationガードのためにstoreの値の初期化を待つ
const storePromise = new Promise((resolve) => {
  resolve(createStoreInstance(axiosPlugin));
});

const routes = [
  {
    path: "/",
    name: "top",
    component: TopView,
    props: true,
    meta: { title: 'トップページ' }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { title: 'ログインページ' },
  },
  {
    path: "/board",
    name: "board",
    component: BoardView,
    meta: { title: '課題解決掲示板ページ' },
  },
  {
    path: "/company",
    name: "company",
    component: CompanyView,
    meta: { title: '企業一覧ページ' },
  },
  ,
  {
    path: "/companyDetail",
    name: "companyDetail",
    component: CompanyDetailView,
    meta: { requiresAuth: true, title: '企業詳細ページ' },
  },
  ,
  {
    path: "/lecture",
    name: "lecture",
    component: LectureView,
    meta: { title: '基調講演ページ' },
  },
  {
    path: "/presentation",
    name: "presentation",
    component: PresentationView,
    meta: { title: 'プレゼンテーションページ' },
  },
  {
    path: "/myPage",
    name: "myPage",
    component: MyPageView,
    meta: { requiresAuth: true, title: 'マイページ' },
  },
  {
    path: "/enqueteThanks",
    name: "enqueteThanks",
    component: EnqueteThanksView,
    meta: { requiresAuth: true, title: 'アンケート完了ページ' },
  },
  ,
  {
    path: "/contactThanks",
    name: "contactThanks",
    component: ContactThanksView,
    meta: { requiresAuth: true, title: 'お問合せ完了ページ' },
  },
  {
    path: "/enquete",
    name: "enqueteIndex",
    component: EnqueteIndexView,
    meta: { requiresAuth: true, title: 'アンケートページ' },
  },
  ,
  {
    path: "/contact",
    name: "contactIndex",
    component: ContactIndexView,
    meta: { requiresAuth: true, title: 'お問合せページ' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // trailingSlash オプションを追加
  trailingSlash: true,
  scrollBehavior(to, from, savedPosition) {

    //もし企業ページ関連じゃなかったらスクロールポジションの履歴を消す
    if (to.name != 'company' && to.name != 'companyDetail') {
      sessionStorage.removeItem('companyScrollPosition');
    }

    //もし企業詳細ページから企業一覧へ戻る場合、もとの企業一覧でのスクロール位置へ戻す
    if (to.name == 'company' && from.name == 'companyDetail') {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({top: sessionStorage.getItem('companyScrollPosition')});
        }, 300);
      })
    }

    if (to.fullPath != '/#about') {
      if (savedPosition) {

        //もし企業詳細ページから企業一覧へ戻る場合、もとの企業一覧でのスクロール位置へ戻す
        //if (to.name == 'company' && from.name == 'companyDetail') {
        //  return new Promise((resolve, reject) => {
        //    setTimeout(() => {
        //      resolve(savedPosition);
        //    }, 300);
        //  })
        //}
        //return savedPosition;

      } else {
        return {
          top: 0
        }
      }
    }
  },
});


router.beforeEach(async (to, from, next) => {
  // ナビゲーションガード内でストアの値を使用
  //const storeAuth = window.appStore;
  try {
    // Vuexストアの初期化を待機
    const store = await storePromise;
    
    // 現在の認証状態を取得。falseの場合authが必要なページにはいけない
    await store.dispatch('checkAuth');

    //awaitでユーザー取得してアナリティクスにアクセスしたことを送信
    //const currentUser = await store.state.currentUser;
    //if (currentUser) {
    //  console.log(currentUser);
    //  //学生か否かで送るデータを変える
    //  if (currentUser.role != 2) {
    //      window.gtag('event', "custom_user_event", {
    //      'event_category': "page_view",
    //      'event_label': "PV",
    //      'user_role': currentUser.role,
    //      'value' : 1
    //    });
    //  } else {
    //    window.gtag('event', "custom_user_event", {
    //      'event_category':　"page_view_student",
    //      'event_label': currentUser.name,
    //      'value' : 1
    //    });
    //  }
    //}

    // もしログインが必要なページで認証がされていない場合、ログインページにリダイレクト
    if (to.meta.requiresAuth && !store.getters.getAuthFlag) {
      console.log(to.fullPath+'へ遷移しようとしたが未ログイン');
      //vuexが効かないためsessionStorageへ遷移しようとしていたパスを保存
      sessionStorage.setItem('failedPath', to.fullPath);
      next({ name: "login" });
    } else {
      //リダイレクトされてないのでsessionStorageへ遷移しようとしていたパスを履き
      //ログインページは除外しないとリダイレクト時に消えてしまうので下記の処理
      if (to.fullPath != '/login') {
        sessionStorage.removeItem('failedPath');
      }

      next();
    }

  } catch (error) {
    // 初期化に失敗した場合はエラーハンドリング
    console.error('Failed to initialize store:', error);
    next(false);
  }
});

router.afterEach((to, from) => {
  document.title = to.meta.title ? 'OTEX おかやまテクノロジー展2024 ONLINE 精鋭企業と出会う技術展示商談会 | ' + to.meta.title : 'OTEX おかやまテクノロジー展2024 ONLINE 精鋭企業と出会う技術展示商談会';
})

export default router;
