<template>
    <body class="login">

    <main id="lecture" class="wrapper wrapperBd">

    <div class="liPankuzu">
      <ul>
        <li><router-link to="/">ホーム</router-link></li>
        <li>基調講演</li>
      </ul>
    </div>
    <!-- li-pankuzu -->

    <section class="secMainview01">
      <div class="secMainview01Inner container containerLarge">
        <h1 class="secMainview01Title">
            <img src="/assets/img/common/txtKichokouen.svg" alt="基調講演" class="secMainview01Jp" />
        </h1>
        <div class="effectBlock effectBlock04"></div>
      </div>
    </section>
    <!-- secMainview01 -->

    <div class="lectureListview container containerLarge">
      <div class="effectBlock effectBlock01"></div>
      <div class="lectureListviewListarea">
        <ul>
          <li @click="fetchLectureDetail(lecture)" v-for="lecture in lectures" :key="lecture.id">

            <a class="linkLecture" v-if="lecture.webinar_flag == false">
              <div class="linkLectureTxt">
                <em>{{lecture.title}}</em>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="5" viewBox="0 0 40 5">
                  <path d="M0,0H40L35,5H0Z" fill="#e52b81" />
                </svg>
                <span>{{lecture.affiliate}}<br />
                  {{lecture.instructor_name}}
                </span>
                <small v-if="lecture.video_url">基調講演を視聴する</small>
              </div>
              <div class="linkLectureImg">
                <img v-if="lecture.instructor_image_path" :src="lecture.instructor_image_path" alt="" @error="lectureAltImg">
                <img v-else src="/assets/img/top/lectureImg02.jpg" alt="{企業名}" />
              </div>
            </a>

            <!--<a :href="lecture.video_url" class="linkLecture webinar" :target="[lecture.video_url ? '_blank' : '']" v-if="lecture.webinar_flag == true">-->
            <a class="linkLecture webinar" v-if="lecture.webinar_flag == true">
              <span class="linkLectureTag"><span class="linkLectureTagInner">ウェビナー配信</span></span>
              <div class="linkLectureTxt">
                <em>{{lecture.title}}</em>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="5" viewBox="0 0 40 5">
                  <path d="M0,0H40L35,5H0Z" fill="#e52b81" />
                </svg>
                <span>{{lecture.affiliate}}<br />
                  {{lecture.instructor_name}}
                </span>
                <small v-if="lecture.video_url">配信ページへ移動する</small>
              </div>
              <div class="linkLectureImg">
                <img v-if="lecture.instructor_image_path" :src="lecture.instructor_image_path" alt="" @error="lectureAltImg">
                <img v-else src="/assets/img/top/lectureImg02.jpg" alt="{企業名}" />
              </div>
            </a>

          </li>
          <!--
          <li>
              ウェビナー配信の場合は、aタグにwebinarクラスを付与してください。
              また、linkLectureTagにウェビナー配信のタグを付与してください。
              small要素のテキストは、 基調講演を視聴する → 配信ページへ移動する に変更になります。
              a要素にhref属性を付与してください。
            <a href="/" class="linkLecture webinar">
              <span class="linkLectureTag"><span class="linkLectureTagInner">ウェビナー配信</span></span>
              <div class="linkLectureTxt">
                <em>基調講演のタイトルが入ります。</em>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="5" viewBox="0 0 40 5">
                  <path d="M0,0H40L35,5H0Z" fill="#e52b81" />
                </svg>
                <span>株式会社〇〇〇〇<br />
                  〇〇〇〇氏
                </span>
                <small>配信ページへ移動する</small>
              </div>
              <div class="linkLectureImg">
                <img src="/assets/img/top/lectureImg01.jpg" alt="">
              </div>
            </a>
          </li>
          -->
          <!--
          <li>
            <a class="linkLecture">
              <div class="linkLectureTxt">
                <em>基調講演のタイトルが入ります。この文章はサンプルです。</em>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="5" viewBox="0 0 40 5">
                  <path d="M0,0H40L35,5H0Z" fill="#e52b81" />
                </svg>
                <span>〇〇〇〇株式会社
                  研究・開発本部 研究開発センター
                  △△△△部 部長
                  〇〇〇〇 氏
                </span>
                <small>基調講演を視聴する</small>
              </div>
              <div class="linkLectureImg">
                <img src="/assets/img/top/lectureImg02.jpg" alt="" />
              </div>
            </a>
          </li>
          -->
        </ul>
      </div>
    </div>
    <!-- listView -->

    <div class="modal active" v-if="showVideoModalFlag">
      <div class="modalWrapper">
        <div class="modalInner">
          <div class="modalClose" @click="hideVideoModal"><img src="/assets/img/common/iconPopupClose.svg" alt="閉じる" width="31" height="31"></div>
          <!-- contents -->
            <vue-element-loading :active="videoLoadingFlag" color="black" spinner="spinner" />
            <iframe @load="onIframeLoad" width="560" height="315" :src="videoUrl(selectedVideoUrl)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <!-- contents -->
        </div>
        <!-- inner -->
      </div>
      <div class="modalBg"></div>
    </div>
    <!-- modal active付与で表示切り替え -->

  </main>
  <!-- #pageID -->
</body>

</template>

<script setup>
  import { ref, onMounted, inject, computed } from 'vue';
  import Splide from '@splidejs/splide';
  import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
  const $axios = inject('$axios');
  import VueElementLoading from "vue-element-loading";
  const route = useRoute();
  const lectures = ref([]);
  const lectureDetail = ref([]);

  import { useStore } from 'vuex';
  //store/index.jsのグローバルデータ
  const store = useStore();
  const router = useRouter();
  const showVideoModalFlag = ref(false);
  const videoLoadingFlag = ref(false);
  const selectedVideoUrl = ref('');

  const authFlag = computed(() => store.state.authFlag);

  const fetchLectures = () => {
    $axios.get('/api/get_lectures')
        .then((res) => {
            console.log(res);
            lectures.value = res.data.lectures;
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(()=>{
        });
  }

  const fetchLectureDetail = (lecture) => {

    //video_urlが設定されてなければこの後の処理は発火しない
    if (!lecture.video_url) {
      console.log('video_urlがないのでここで終了');
      return;
    }

    if (lecture.webinar_flag == true) {
      window.open(lecture.video_url);
    }

    //まだログインしてない時はログインページへ飛ばす
    if (authFlag.value == false) {
      //ログイン後にここに戻って来れるようにパスを保存
      store.commit('setTopPageLectureInfo', lecture.id);
      sessionStorage.setItem('failedPath', route.fullPath);
      return router.push('/login');
    }

    $axios.get('/api/get_lecture_detail?lecture_id='+lecture.id)
      .then((res) => {
          console.log(res);
          lectureDetail.value = res.data.lecture_detail;
      })
      .catch((err) => {
          console.log(err);
      })
      .finally(()=>{
        gtagViewLecture(lectureDetail.value.title);
        //webinarじゃない場合モーダルを出す
        if (lectureDetail.value.webinar_flag == false) {
          selectedVideoUrl.value = lectureDetail.value.video_url;
          showVideoModal();
        }
      });
  }

  const lectureAltImg = (event) => {
    event.target.src = "/assets/img/top/lectureImg01.jpg"; 
  }

  const showVideoModal = () => {
    showVideoModalFlag.value = true;
    videoLoadingFlag.value = true;
  }

  const hideVideoModal = () => {
    showVideoModalFlag.value = false;
  }

  const onIframeLoad = () => {
    videoLoadingFlag.value = false;
  }

  const videoUrl = (video_url) => {
    console.log(video_url);
    const array = video_url.split('/');
    let youTubeId = array[array.length - 1];
    youTubeId = youTubeId.replace('watch?v=', ''); //watch?v=を削除
    console.log(youTubeId);
    return `https://www.youtube.com/embed/${youTubeId}`;
  }

  //トップページに表示されている基調講演を押した時に発火 (格納されているidを渡して情報を取得後モーダル出現)
  const showTopPageLecture = () => {
    if (store.state.topPageLectureInfo) {

      //topから来てまだログインしてない時はログインページへ飛ばす
      if (store.state.unAuthLinkFromTopPageFlag == true) {
        //ログイン後にここに戻って来れるようにパスを保存
        sessionStorage.setItem('failedPath', route.fullPath);
        return router.push('/login');
      }

      //まだログインしてない時はモーダル開けない trueすなわち認証している場合のみ発火
      if (authFlag.value == true) {
        fetchLectureDetail(store.state.topPageLectureInfo);
      }

      //値をリセット
      store.commit('resetTopPageLectureInfo');
    } 
  }

  //基調講演視聴
  const gtagViewLecture = (title) => {
    const label = title;
    gtag('event', "custom_user_event", {
      'event_category': "view_lecture_video",
      'event_label': label,
      'value' : 1
    });
  }

  onMounted(()=>{
    fetchLectures();
    showTopPageLecture();
  });

</script>