<template>
    <footer class="footer">
        <nav class="footerSitemap">
            <div class="footerSitemapInner container containerLarge">
                <div class="footerSitemapInfo">
                <router-link to="/">
                    <img src="/assets/img/common/logo.png" alt="OTEX" width="161" height="51" loading="lazy">
                    <picture>
                        <source media="(min-width: 961px)" srcset="/assets/img/common/logoTitle.svg">
                        <source media="(max-width: 960px)" srcset="/assets/img/common/logoTitleSp.svg">
                        <img src="/assets/img/common/logoTitle.svg" alt="おかやまテクノロジー展2024 ONLINE 精鋭企業と出会う技術展示商談会" width="354" height="39" loading="lazy">
                    </picture>
                </router-link>
                </div>
                <div class="footerSitemapList">
                    <div class="footerSitemapListBox">
                        <ul>
                            <li><router-link to="/#about" class="linkUnderline smoothScroll">OTEXとは</router-link></li>
                            <li><router-link to="/company" class="linkUnderline">出展企業情報</router-link></li>
                            <li><router-link to="/lecture" class="linkUnderline">基調講演</router-link></li>
                        </ul>
                    </div>
                    <div class="footerSitemapListBox">
                        <ul>
                            <li><router-link to="/presentation" class="linkUnderline">出展者<br class="pcNone">プレゼンテーション</router-link></li>
                            <li><router-link to="/board" class="linkUnderline">課題解決掲示板</router-link></li>
                            <li v-if="currentUser.role_id != 4"><router-link to="/myPage" class="linkUnderline">マイページ</router-link></li>
                        </ul>
                    </div>
                    <div class="footerSitemapListBtn">
                        <router-link to="/contact" class="btn01 btn01Color" v-if="currentUser.role_id != 4"><img src="/assets/img/common/iconMailWhite.svg" alt="" width="23" height="17">お問合せ</router-link>
                        <router-link to="/enquete" class="btn01"><img src="/assets/img/common/iconEnqueteWhite.svg" alt="" width="19" height="23" >アンケート</router-link>
                        <ul>
                            <li>
                                <router-link to="/login" class="linkIcon" v-if="authFlag == false"><img src="/assets/img/common/iconLogin.svg" alt="">ログイン</router-link>
                                <span class="linkIcon" v-if="authFlag == true" @click="logout"><img src="/assets/img/common/iconLogout.svg" alt="">ログアウト</span>
                            </li>
                            <li><a href="https://miceform.jp/otex2024/form/" class="linkIcon"><img src="/assets/img/common/iconAdmin.svg" alt="">会員登録</a></li>
                        </ul>
                    </div>
                </div>
                <!-- list -->
            </div>
            <!-- inner -->

            <div class="navBanner container">
                <ul>
                <li>
                    <a href="https://www.optic.or.jp/otex/" target="_blank" rel="noopener">
                    <picture>
                        <source media="(min-width: 961px)" srcset="/assets/img/common/bannerOtex.png">
                        <source media="(max-width: 960px)" srcset="/assets/img/common/bannerOtexSp.png">
                        <img src="/assets/img/common/bannerOtex.png" alt="おかやまテクノロジー展2024" >
                    </picture>
                    <span class="linkExternal">OTEX公式HPはこちら<img src="/assets/img/common/iconExternalLink.svg" alt=""></span>
                    </a>
                </li>
                </ul>
            </div>
            <!--  navBanner  -->
            </nav>
            <!--  footerSitemap  -->

            <div class="footerOutro container containerLarge">
                <div class="footerOutroInner">
                    <div class="footerOutroInnerBox">
                    <a href="https://www.optic.or.jp/" target="_blank"><img src="/assets/img/common/bannerOPIPF.png" alt="公益財団法人 岡山県産業振興財団" width="270" height="65" loading="lazy" ></a>
                    <p>公益財団法人 岡山県産業振興財団<br/>
                        〒701-1221 岡山市北区芳賀5301<br class="pcNone">（テクノサポート岡山内）<br/>
                        TEL:086-286-9670  FAX:086-286-9671
                    </p>
                </div>
            </div>
            <p class="footerOutroPrivacy">Copyright © Okayama Prefecture <br class="pcNone"> Industrial Promotion Foundation All Rights Reserved.</p>
        </div>
        <!--  footerOutro  -->
    </footer>
</template>
<script setup>
    import { ref, onMounted, inject, watch, computed } from 'vue';
    import Splide from '@splidejs/splide';
    import { useToast } from "vue-toastification";
    const toast = useToast();
    import { onBeforeRouteLeave } from 'vue-router';
    import { useStore } from 'vuex';
    //store/index.jsのグローバルデータ
    const store = useStore();
    //main.jsで定義したグローバルaxios
    const $axios = inject('$axios');
    //変数
    const authFlag = computed(() => store.state.authFlag);
    import { useRouter } from 'vue-router';
    const router = useRouter();
    const currentUser = computed(() => store.state.currentUser);


    const logout = () => {
        $axios.post('/api/logout')
            .then((res) => {
                console.log(res);
                //storeの値変更
                store.commit('setAuthFlag', false);
                store.commit('setCurrentUser', '');
                sessionStorage.removeItem('unAuthReloadFlag');
                //toast("ログアウトしました!", {
                //    position: "top-left",
                //    timeout: 1000,
                //    closeOnClick: true,
                //    pauseOnFocusLoss: true,
                //    pauseOnHover: true,
                //    draggable: true,
                //    draggablePercent: 0.6,
                //    showCloseButtonOnHover: false,
                //    hideProgressBar: true,
                //    closeButton: "button",
                //    icon: true,
                //    rtl: false
                //});
                setTimeout(() => {
                    router.push('/');
                }, 200);
            })
            .catch((err) => {
                console.log(err);
            });
    }
</script>
<style scoped>
    span {
        cursor: pointer;
    }
</style>